import React, { useContext, useEffect, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import { navigate } from 'gatsby'
import { CircularProgress, Container, Grid, Input, Button } from '@material-ui/core'
import localStorage from 'localStorage'
import debounce from 'lodash/debounce'
import Context from 'reactContext'
import SnackMessage from 'components/snackbar'
import Nav from 'components/nav'
import { post, get } from 'utils/request'

const Signup = () => {
  const { viewSetIn, view } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [contactName, setContactName] = useState('')
  const [contactMobile, setContactMobile] = useState('')
  const [open, setOpen] = useState(false)
  const [resMessage, setResMessage] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const signup = async (params) => {
    const res = await post('managers', params)
    if (res.id) {
      localStorage.setItem('USER_TYPE', 'pending manager')
      setResMessage('已成功提交申请，请等待审核')
    } else {
      setResMessage('注册失败')
    }
    setOpen(true)
    setLoading(false)
  }

  const getReferrerName = async (giftCardNumber) => {
    const res = await get('agents/gift_cards/referrer_name', {
      gift_card_number: giftCardNumber,
    })
    if (res.ok) {
      viewSetIn(['referrerName'], res.name)
    }
  }

  const onReferrerIdDebounced = (giftCardNumber) => {
    getReferrerName(giftCardNumber)
  }

  const onInputChange = (field, value) => {
    viewSetIn(['signup', field], value)
    if (field === 'gift_card_number') {
      const debounced = debounce(onReferrerIdDebounced, 800)
      debounced(value)
    }
  }

  useEffect(() => {
    getReferrerName(window.location.search.substring(1).split('=')[1] || view.getIn(['signup', 'gift_card_number']))
    if (localStorage.getItem('Token')) {
      navigate('/')
    }
  }, [])

  return (
    <Nav>
      <Container maxWidth="xs">
        <Grid container>
          <Grid item xs className="py-32">
            <div className="font-bold mb-4 text-xl">企业信息</div>
            <Input autoFocus placeholder="企业名称" fullWidth value={name} onChange={(e) => setName(e.target.value)} />
            <Input autoFocus placeholder="联系人" fullWidth className="mt-6" value={contactName} onChange={(e) => setContactName(e.target.value)} />
            <Input autoFocus placeholder="联系电话" fullWidth className="mt-6 mb-4" value={contactMobile} onChange={(e) => setContactMobile(e.target.value)} />
            <div className="font-bold mb-3 text-xl">管理员信息</div>
            <Input autoFocus fullWidth placeholder="邮箱" value={username} onChange={(e) => setUsername(e.target.value)} />
            <Input className="mt-6" fullWidth placeholder="密码" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            <Input className="mt-6" placeholder="确认密码" type="password" fullWidth value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
            <Input
              className="mt-6"
              placeholder="邀请码"
              fullWidth
              disabled={!isEmpty(view.get('referrerName'))}
              onChange={(e) => onInputChange('gift_card_number', e.target.value)}
            />
            {view.get('referrerName') ? <Input placeholder="推荐者" className="mt-6" fullWidth disabled value={`推荐者：${view.get('referrerName')}`} /> : null}
            <Button
              color="primary"
              fullWidth
              variant="contained"
              disabled={!view.get('referrerName')}
              size="large"
              className="mt-8"
              onClick={() => {
                setLoading(true)
                signup({
                  name,
                  contact_name: contactName,
                  contact_mobile: contactMobile,
                  email: username,
                  password,
                  password_confirmation: passwordConfirmation,
                  gift_card_number: window.location.search.substring(1).split('=')[1] || view.getIn(['signup', 'gift_card_number']),
                })
              }}
            >
              {loading ? <CircularProgress size={24} /> : '注册'}
            </Button>
          </Grid>
        </Grid>
        <SnackMessage open={open} onClose={() => setOpen(false)} message={resMessage} status="info" />
      </Container>
    </Nav>
  )
}

export default Signup
